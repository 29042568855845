<script lang="ts">
	import Footer from "$components/Footer.svelte";
	import Header from "$components/Header.svelte";
	import { i18n } from "$lib/i18n";
	import "$src/app.css";
	import { ParaglideJS } from "@inlang/paraglide-js-adapter-sveltekit";
	import { ModeWatcher } from "mode-watcher";
</script>

<svelte:head>
	<script type="text/javascript">
		window.$crisp = [];
		window.CRISP_WEBSITE_ID = "785eda68-012c-4509-9961-3ffa3ddcc5d9";
		(function () {
			d = document;
			s = d.createElement("script");
			s.src = "https://client.crisp.chat/l.js";
			s.async = 1;
			d.getElementsByTagName("head")[0].appendChild(s);
		})();
	</script>
</svelte:head>

<ModeWatcher />
<ParaglideJS {i18n}>
	<main class="app">
		<header>
			<section>
				<Header />
			</section>
		</header>
		<main>
			<section>
				<slot />
			</section>
		</main>
		<!-- {#if data.teamInfo}
			<pre>{JSON.stringify(data.teamInfo.name)}</pre>
		{/if} -->
		<footer
			class="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t mb-8"
		>
			<Footer></Footer>
		</footer>
	</main>
</ParaglideJS>
